import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring/renderprops';
import { ChevronRight } from 'react-feather';

import Social from './Social';
import styles from './Intro.module.css';
import Meta from '../components/Meta';

const Intro = ({ style, mouseEnter, mouseLeave }) => {
  return (
    <animated.section style={style} className={styles.intro}>
      <Meta title='Daniel Beckley' url='https://danielbeckley.com/' />
      <div className={styles.image}>
        <figure>
          <img
            src='https://res.cloudinary.com/dk0k3povu/image/upload/v1698677616/ab_s7spf9.png'
            alt=''
          />
        </figure>
      </div>
      <div className={styles.about}>
        <p className={styles.skills}>
          artificial intelligence, machine learning, web, web3, python, c++,
          typescript.
        </p>
        <h2 className={styles.title}>Frontend Engineer</h2>
        <p className={styles.desc}>
          Hello! I'm Daniel Beckley, an AI and Machine Learning Engineer with a
          strong background in Fullstack Javascript development.I’m passionate
          about leveraging AI to build intelligent, data-driven applications and
          systems. You can find some of my AI/ML projects{' '}
          <Link
            to='/projects/ai-ml'
            className={styles.next_ai}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            here
          </Link>.
          <br />
          <br />
          My expertise in frontend engineering, combined with a deep
          understanding of AI, allows me to create intuitive, user-centric
          solutions. In my spare time, you can find me learning C++ for
          Competitive Programming.
        </p>
        <Social mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
        <Link
          to='/projects'
          className={styles.next}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        >
          Work Experience <ChevronRight />
        </Link>
      </div>
    </animated.section>
  );
};

export default Intro;
